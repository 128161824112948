import React from 'react';

const Footer = () => {
    const currentYear = new Date().getFullYear();
    // display: flex;
    // height: 100%;
    // flex-direction: column;
    // justify-content: flex-end;
    // align-items: center;
    // padding: 0.5rem;
    return (
        <footer className="flex flex-col justify-end text-center pt-8 px-4 pb-4 text-0 w-full h-full">
            <div className="flex flex-row items-end w-fit mx-auto">
                <img className="w-14 mx-auto pb-4" src="/images/foxwood_illustration.svg"/>
                <img className="w-40 mx-auto pb-8" src="/images/logo.svg" alt="logo"/>
            </div>
            Made with 💛 by Ed & Penny {currentYear}
        </footer>
    );
};

export default Footer;
