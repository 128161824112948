import React, { useEffect, useContext } from "react";
import { LoadingContext } from '../helper/LoadingContext';
// import Carousel from '../components/Carousel';
import PhotoShoot from '../components/PhotoShoot'
import WeddingPhoto from '../components/WeddingPhoto'

// const imageCount = 17;
// const images = Array.from({ length: imageCount }, (_, index) => `/images/photos/${index + 1}.jpg`);

export default function Photos() {
  const loadingContext = useContext(LoadingContext);

  // useEffect(() => {
  //   images.forEach((src) => {
  //     const img = new Image();
  //     img.src = src;
  //   });
  // }, []);

  return !loadingContext.isLoading ? (
    <div>
      <div className="text-center">
        {/* <Carousel images={images}/> */}
        <h2 className="text-3 pb-2 font-bold">婚紗精選</h2>
        <PhotoShoot/>
        <h2 className="text-3 pb-2 font-bold mt-8">婚禮精選</h2>
        <WeddingPhoto/>
      </div>
    </div>
  ) : null;
}
