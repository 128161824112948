import React, { useState, useEffect } from "react";
import svg1 from "../images/constructionone.svg";
import svg2 from "../images/constructiontwo.svg";
import svg3 from "../images/constructionthree.svg";

const Construction = () => {
  const [currentFrame, setCurrentFrame] = useState(1);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentFrame((prevFrame) => (prevFrame % 3) + 1);
    }, 200);

    return () => clearInterval(interval);
  }, []);

  return (
      <div className="">
        <img
          className="w-20 m-auto"
          src={currentFrame === 1 ? svg1 : currentFrame === 2 ? svg2 : svg3}
          alt="construction"
        />
      </div>
  );
};

export default Construction;
