import React, { useContext } from "react";
import { LoadingContext } from '../helper/LoadingContext';
// import Construction from '../components/Construction'

export default function Videos() {
    const loadingContext = useContext(LoadingContext);

    return (
        <div>
            {!loadingContext.isLoading ? (
                <div className="text-center mt-8 m-auto">
                    {/* <Construction /> */}
                    {/* Video embed */}
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/ugNK8rrralI"
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                        className="inline-block"
                    ></iframe>
                </div>
            ) : (
                <div>Loading...</div>
            )}
        </div>
    );
}
