import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import { LoadingContext } from '../helper/LoadingContext';
import FloatingStuff from '../components/FloatingStuff';
import Twitch from '../components/Twitch';


export default function Home() {
    const loadingContext = useContext(LoadingContext);

    return !loadingContext.isLoading ? (
        <div>
            <div className="text-center">
                <h1 className='text-4 font-bold'>你好，我們要結婚啦</h1>
                <img className="w-80 m-auto mt-4" src="/images/edogoandpeneko.svg"/>
            </div>
            {/* <div className="flex justify-center">
                <Twitch channel="horseh"/>
            </div> */}
            {/* <hr className="w-2/4 mx-auto mt-10 mb-8 border-secondary border-2 rounded-lg"/>
            <div className="m-auto text-center max-w-[500px]">
                <h2 className="text-3 pb-2 font-bold">什麼是 "The Tale of Foxwood"?</h2>
                <p className="">是我們的婚禮主題！不過由於不想要太制式的 "Ed & Penny" 命名，所以想了一些特別的主題命名方式。後來覺得 Ed 姓胡 (fox)、Penny 姓林 (forest/wood)，乾脆用這個命名組合，然後延伸設計出一套有趣的主題設計邏輯，讓整體婚禮好玩，風格也一致。</p>
            </div> */}
                <FloatingStuff className="lg:block lg:w-[5rem] lg:animate-float-0 lg:left-[10rem] lg:top-[24rem] hidden" svgPath={"./images/luggage.svg"} alt={"luggage"}/>
                <FloatingStuff className="lg:block lg:w-[8rem] lg:animate-float-0 lg:right-[10rem] lg:top-[40rem] hidden" svgPath={"./images/bike.svg"} alt={"bike"}/>
                <FloatingStuff className="lg:block lg:w-[6rem] lg:animate-float-1 lg:left-[11rem] lg:top-[36rem] hidden" svgPath={"./images/snowboard.svg"} alt={"snowboard"}/>
                <FloatingStuff className="lg:block lg:w-[4rem] lg:animate-float-0 lg:right-[5rem] lg:top-[22rem] hidden" svgPath={"./images/silverware.svg"} alt={"silverware"}/>
                <FloatingStuff className="lg:block lg:w-[6rem] lg:animate-float-1 lg:right-[8rem] lg:top-[29rem] hidden" svgPath={"./images/cat.svg"} alt={"cat"}/>
                <FloatingStuff className="lg:block lg:w-[7rem] lg:animate-float-1 lg:right-[10rem] lg:top-[18rem] hidden" svgPath={"./images/memories.svg"} alt={"memories"}/>
                <FloatingStuff className="lg:block lg:w-[5rem] lg:animate-float-2 lg:left-[5rem] lg:top-[31rem] hidden" svgPath={"./images/gamepad.svg"} alt={"gamepad"}/>
                <FloatingStuff className="lg:block lg:w-[6rem] lg:animate-float-1 lg:left-[6rem] lg:top-[15rem] hidden" svgPath={"./images/divegear.svg"} alt={"divegear"}/>
        </div>
    ) : null;
}
