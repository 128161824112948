import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import { LoadingContext } from '../helper/LoadingContext';
import FloatingStuff from '../components/FloatingStuff';
import ThankYou from '../components/ThankYou'


export default function Confirmation() {
    const loadingContext = useContext(LoadingContext);

    return !loadingContext.isLoading ? (
        <div>
            <div className="text-center">
                <div className="mt-28 h-[5rem]">
                    <ThankYou/>
                </div>
                <h1 className='text-4 font-bold mb-4'>謝謝你的填寫！</h1>
                <p>收到你的資訊了！如果有任何異動也歡迎跟我們說。</p>
                <p className="italic text-1 mt-4">— 佳霖 & 哲皓</p>
            </div>
            <FloatingStuff className="md:block md:w-[5rem] md:animate-float-0 md:left-[10rem] md:top-[24rem] hidden" svgPath={"./images/luggage.svg"} alt={"luggage"}/>
                <FloatingStuff className="md:block md:w-[8rem] md:animate-float-0 md:right-[10rem] md:top-[40rem] hidden" svgPath={"./images/bike.svg"} alt={"bike"}/>
                <FloatingStuff className="md:block md:w-[6rem] md:animate-float-1 md:left-[11rem] md:top-[36rem] hidden" svgPath={"./images/snowboard.svg"} alt={"snowboard"}/>
                <FloatingStuff className="md:block md:w-[4rem] md:animate-float-0 md:right-[5rem] md:top-[22rem] hidden" svgPath={"./images/silverware.svg"} alt={"silverware"}/>
                <FloatingStuff className="md:block md:w-[6rem] md:animate-float-1 md:right-[8rem] md:top-[29rem] hidden" svgPath={"./images/cat.svg"} alt={"cat"}/>
                <FloatingStuff className="md:block md:w-[7rem] md:animate-float-1 md:right-[10rem] md:top-[18rem] hidden" svgPath={"./images/memories.svg"} alt={"memories"}/>
                <FloatingStuff className="md:block md:w-[5rem] md:animate-float-2 md:left-[5rem] md:top-[31rem] hidden" svgPath={"./images/gamepad.svg"} alt={"gamepad"}/>
                <FloatingStuff className="md:block md:w-[6rem] md:animate-float-1 md:left-[6rem] md:top-[15rem] hidden" svgPath={"./images/divegear.svg"} alt={"divegear"}/>
        </div>
    ) : null;
}
