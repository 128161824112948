import React from "react";
import { useRandomFactor } from "../helper/useRandomFactor";

const FloatingStuff = ({ svgPath, className, alt }) => {

  return (
    <img src={svgPath} alt={alt} className={`absolute transform -translate-y-1/2 ${className}`} />
  );
};

export default FloatingStuff;
