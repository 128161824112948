import React, { useContext, useState, useRef, useEffect  } from "react";
import { Link } from 'react-router-dom';
import { LoadingContext } from '../helper/LoadingContext';
import FloatingStuff from '../components/FloatingStuff';
import ThankYou from '../components/ThankYou'


export default function Invitation() {
    const loadingContext = useContext(LoadingContext);
    

    return !loadingContext.isLoading ? (
        <div className="flex flex-col justify-center items-center gap-4">
            <img src="/images/map.png" className="md:w-3/5"></img>
            {/* <span>
                <p>
                    食尚曼谷餐廳 (
                    <a
                        className="underline"
                        href="https://goo.gl/maps/ymjbMyfxmXt6Xwt49"
                        target="_blank"
                        rel="noopener"
                    >
                        台北市士林區大東路54號
                    </a>
                    )
                </p>
            </span> */}
            <div className="mx-auto mt-8 flex flex-col justify-center">
                <hr className="w-1/3 mx-auto mt-5 mb-4 border-secondary border-1 rounded-lg" />
                <div className="w-full mx-auto md:w-fit">
                    <p>日期：2023 年 11 月 25 日 (六)</p>
                    <p>時間：5:30 PM 入席，6:00 PM 開始</p>
                    <p>
                    場地：食尚曼谷餐廳 (
                    <a
                        className="underline"
                        href="https://goo.gl/maps/ymjbMyfxmXt6Xwt49"
                        target="_blank"
                        rel="noopener"
                    >
                        台北市士林區大東路54號
                    </a>
                    )
                    </p>
                    <p>電話：(02) 2883 - 0013</p>
                </div>
                <hr className="w-1/3 mx-auto mt-5 mb-4 border-secondary border-1 rounded-lg" />
                <div className="w-full mx-auto md:w-1/2">
                    <p className="text-2 font-bold mb-1">搭捷運</p>
                    <p>從士林站 2 號出口沿著大東路走 7 分鐘，或劍潭站1號出口沿大東路走 9 分鐘抵達</p>
                    <p className="text-2 font-bold mt-4 mb-1">自行開車</p>
                    <p>餐廳位於士林夜市內，無配合的停車場，建議停至附近大馬路上的停車場再走過來，可搜尋「Times士林福德路停車場」、「嘟嘟房承德公園站」、「嘟嘟房士林基河站」或停至其他停車場</p>
                    <p className="italic mt-4 text-0">備註：鄰近停車場皆離餐廳 5 - 10 分鐘步程，記得預留時間提早來，如會喝酒就不要開車喔</p>
                </div>
                <hr className="w-1/3 mx-auto mt-5 mb-4 border-secondary border-1 rounded-lg" />
            </div>
        </div>
    ) : null;
}
