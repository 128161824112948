import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import { LoadingContext } from '../helper/LoadingContext';
import Construction from '../components/Construction'


export default function Where() {
    const loadingContext = useContext(LoadingContext);

    return !loadingContext.isLoading ? (
        <div>
            <div className="text-center mt-8">
                <Construction/>
            </div>
        </div>
    ) : null;
}
