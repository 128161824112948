import React, { useEffect, useContext } from "react";
import { useLocation, Routes, Route } from "react-router-dom";
import { LoadingContext } from '../helper/LoadingContext';
import Nav from "../sections/Nav";
import Footer from "../sections/Footer";
import Home from "../pages/Home";
import Loading from "./Loading";
import About from "../pages/About";
import RSVP from "../pages/Rsvp";
import Photos from "../pages/Photos";
import Videos from "../pages/Videos";
import Where from "../pages/Where";
import ThankYou from "../pages/Confirmation";
import Location from "../pages/Location";
import Invitation from "../pages/Invitation";
import "../styles/index.css";

export default function AppContent() {
    const loadingContext = useContext(LoadingContext);
    const location = useLocation();
  
    useEffect(() => {
      loadingContext.setIsLoading(true);
      window.scrollTo(0, 0);
      const timer = setTimeout(() => {
        loadingContext.setIsLoading(false);
      }, 1000);
      return () => clearTimeout(timer);
    }, [location.pathname]);
  
    const routes = [
      { path: "/", component: Home, title: "Home" },
      { path: "/about", component: About, title: "About" },
      { path: "/rsvp", component: RSVP, title: "RSVP" },
      { path: "/photos", component: Photos, title: "Photos" },
      { path: "/videos", component: Videos, title: "Videos" },
      { path: "/where", component: Where, title: "Where" },
      { path: "/thankyou", component: ThankYou, title: "Thank You" },
      { path: "/location", component: Location, title: "Location" },
      { path: "/invitation", component: Invitation, title: "Invitation" },
      // Add more routes as needed...
    ];
  
    return (
      <div className="flex flex-col justify-between text-primary md:text-1">
        <Loading />
        <Routes>
          {routes.map(({ path, component: Component }) => (
            <Route
              key={path}
              path={path}
              element={
                loadingContext.isLoading ? null : (
                  <div className="flex flex-col h-screen">
                    <Nav />
                    <div className="flex flex-col justify-start mx-auto p-4 w-full lg:w-[1000px]">
                      <Component />
                    </div>
                    <Footer />
                  </div>
                )
              }
            />
          ))}
        </Routes>
      </div>
    );
}