import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from '../helper/LoadingContext';


export default function Invitation() {
    const loadingContext = useContext(LoadingContext);
    const navigate = useNavigate();

    return !loadingContext.isLoading ? (
        <div>
            <div className="flex-col text-center mt-8 flex md:flex-row gap-4">
                <img className="w-full ml-auto mr-auto md:w-1/2" src="./images/invitation_front.jpg"></img>
                <img className="w-full ml-auto mr-auto md:w-1/2" src="./images/invitation_back.jpg"></img>
            </div>
            <div
                className="p-2 bg-primary text-white rounded cursor-pointer w-fit text-2 mt-4 mx-auto"
                onClick={ async () => {
                    navigate('/location')}
                }
                >
                交通資訊請點我
                </div>
        </div>
    ) : null;
}
