import React, { useState, useEffect, useContext } from "react";
import { CSSTransition } from 'react-transition-group';
import { LoadingContext } from '../helper/LoadingContext';
import svg1 from "../images/loadingone.svg";
import svg2 from "../images/loadingtwo.svg";
import svg3 from "../images/loadingthree.svg";

const Loading = () => {
  const [currentFrame, setCurrentFrame] = useState(1);
  const loadingContext = useContext(LoadingContext);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentFrame((prevFrame) => (prevFrame % 3) + 1);
    }, 200);

    return () => clearInterval(interval);
  }, []);

  return (
    <CSSTransition
      in={loadingContext.isLoading}
      timeout={300}
      classNames="loader"
      unmountOnExit
      appear={false}
    >
      <div className="flex absolute top-0 left-0 w-screen h-screen bg-beige z-20">
        <img
          className="w-40 m-auto"
          src={currentFrame === 1 ? svg1 : currentFrame === 2 ? svg2 : svg3}
          alt="loading"
        />
      </div>
    </CSSTransition>
  );
};

export default Loading;
