import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoadingContext } from "../helper/LoadingContext";
import axios from 'axios';


export default function Rsvp() {
  const loadingContext = useContext(LoadingContext);
  const [formValues, setFormValues] = useState({
    email: "",
    name: "",
    availability: "",
    attendees: "",
    foodPreference: "",
    ceremonyParticipation: "",
    invitationType: "",
    phone: "",
    address: "",
    comment: "",
  });
  const [available, setAvailable] = useState(false);
  const [able, setAble] = useState(null);
  const [unsure, setUnsure] = useState(null);
  const [eCard, setEcard] = useState(null);
  const navigate = useNavigate();

  const handleChange = (event) => {
    if (event.target.name === "availability") {
      if (event.target.value === "有空 ! 加我加我") {
        setAvailable(true);
        setAble(true);
        setUnsure(null);
      } else if (event.target.value === "無法出席") {
        setAvailable(true);
        setAble(false);
        setUnsure(null);
      } else if (event.target.value === "還不確定，我晚點確認喔") {
        setAvailable(true);
        setAble(null);
        setUnsure(true);
      } else if (!event.target.value) {
        setAvailable(false);
        setAble(null);
        setUnsure(null);
      }
    }

    if (event.target.name === "invitationType") {
      if (event.target.value === "想收紙本和電子喜帖") {
        setEcard(true);
      } else if (event.target.value === "我收電子喜帖就好囉") {
        setEcard(false);
      }
    }

    setFormValues({
      ...formValues,
      [event.target.name]: event.target.value,
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        'https://foxwood-rsvp.vextapp.workers.dev/01060924', 
        formValues, 
        {
          headers: { 'Content-Type': 'application/json' }
        }
      );
  
      if (response.status === 200) {
        navigate('/thankyou');
      }
    } catch (error) {
      console.log('Error sending data to Zapier', error);
    }
  };

  

  return !loadingContext.isLoading ? (
    <div className="max-w-full min-w-full m-auto md:max-w-[500px] md:min-w-[500px]">
      {/* <h1 className="text-4 font-bold text-center">邀請你來我們的婚禮派對😚</h1> */}
      <h1 className="text-4 font-bold text-center">RSVP 已截止 🤔</h1>
      <p className="text-center mt-2">
        已經填表單的但是還沒有收到任何資訊請聯絡我們哦！
      </p>
      <img className="w-40 ml-auto mr-auto mt-8 mb-8" src="./images/bike.svg"></img>
      <div className="flex flex-row gap-4 justify-center">
        <div
          className="p-2 bg-primary text-white rounded cursor-pointer w-fit text-2"
          onClick={ async () => {
            navigate('/location')}
          }
        >
          交通資訊請點我
        </div>
        <div
            className="p-2 bg-primary text-white rounded cursor-pointer w-fit text-2"
            onClick={ async () => {
              navigate('/invitation')}
            }
        >
          電子喜帖請點我
        </div>
      </div>
      {/* <div className="mx-auto mt-8 w-fit">
        <hr className="w-2/4 mx-auto mt-5 mb-4 border-secondary border-1 rounded-lg" />
        <p>時間：2023 11/25 (Sat) 預計 6PM 準時開始</p>
        <p>
          場地：食尚曼谷餐廳 (
          <a
            className="underline"
            href="https://goo.gl/maps/ymjbMyfxmXt6Xwt49"
            target="_blank"
            rel="noopener"
          >
            台北市士林區大東路54號
          </a>
          )
        </p>
        <hr className="w-2/4 mx-auto mt-5 mb-4 border-secondary border-1 rounded-lg" />
      </div> */}
      {/* <div className="mx-auto mt-8 w-fit">
        <p className="mt-8">
          再請你幫我們填寫表單，之後有任何異動都歡迎隨時跟我們說喔！
        </p>
        <p className="text-right italic">— 佳霖 & 哲皓</p>
      </div> */}

      {/* <div id="mc_embed_signup" className="mt-8 mx-auto w-full">
        <form
          // action="https://gmail.us10.list-manage.com/subscribe/post?u=8afc1b0e5b57a272217f3c012&amp;id=67766a492c&amp;f_id=0082e6e5f0"
          // method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          target="_blank"
          // onSubmit={() => {
          //   setTimeout(() => {
          //     navigate("/");
          //   }, 3000);
          // }}
          onSubmit={handleSubmit}
        >
          <div className="mc-field-group">
            <label htmlFor="mce-FULL_NAME">
              請問你的姓名是? (如有暱稱也可以一併填寫喔){" "}
              <span className="asterisk">*</span>
            </label>
            <input 
              value={formValues.name}
              onChange={handleChange}
              type="text" 
              name="name" 
              id="mce-FULL_NAME" 
              required 
            />
          </div>
          <div className="mc-field-group">
            <label htmlFor="mce-AVAILABLE">
              是否有空來參加我們的晚宴派對呢?{" "}
              <span className="asterisk">*</span>
            </label>
            <select
              value={formValues.availability}
              onChange={handleChange}
              name="availability"
              className="required"
              id="mce-AVAILABLE"
            >
              <option value=""></option>
              <option value="有空 ! 加我加我">有空 ! 加我加我</option>
              <option value="還不確定，我晚點確認喔">
                還不確定，我晚點確認喔
              </option>
              <option value="無法出席">無法出席</option>
            </select>
          </div>

          {available && (
            <>
              {able ? (
                <>
                  <div className="mc-field-group">
                    <label htmlFor="mce-ATTENDEE">
                      晚宴參加人數 (因場地限制關係,
                      如需攜伴或帶小孩可以先跟我們討論喔){" "}
                      <span className="asterisk">*</span>
                    </label>
                    <select
                      value={formValues.attendees}
                      onChange={handleChange}
                      name="attendees"
                      className="required"
                      id="mce-ATTENDEE"
                    >
                      <option value=""></option>
                      <option value="1人">1人</option>
                      <option value="2人">2人</option>
                    </select>
                  </div>

                  <div className="mc-field-group">
                    <label htmlFor="mce-FOOD">
                      需要為你準備素食餐點嗎?{" "}
                      <span className="asterisk">*</span>
                    </label>
                    <select
                      value={formValues.foodPreference}
                      onChange={handleChange}
                      name="foodPreference"
                      className="required"
                      id="mce-FOOD"
                    >
                      <option value=""></option>
                      <option value="不需要">不需要素食餐</option>
                      <option value="需要">需要素食餐</option>
                    </select>
                  </div>
                </>
              ) : null}

              {able && unsure === null ? (
                <>
                  <hr className="w-2/4 mx-auto mt-10 mb-8 border-secondary border-2 rounded-lg" />
                  <h2 className="text-3 font-bold text-center">
                    請留下您的聯繫資料，如需紙本喜帖也幫忙留下地址喔！
                  </h2>
                </>
              ) : able === false && unsure === null ? (
                <p className="text-2 font-bold text-center">
                  好可惜 😭 不過還是謝謝你填寫，之後再來約！
                </p>
              ) : null}

              {unsure && (
                <p className="text-2 font-bold text-center">
                  沒關係，決定了可以再跟我們說，或是回來填寫！
                </p>
              )}

              <div className="mc-field-group mt-8">
                <label htmlFor="mce-INVITATION">
                  是否需要寄送喜帖 <span className="asterisk">*</span>
                </label>
                <select
                  value={formValues.invitationType}
                  onChange={handleChange}
                  name="invitationType"
                  className="required"
                  id="mce-INVITATION"
                >
                  <option value=""></option>
                  <option value="我收電子喜帖就好囉">我收電子喜帖就好囉</option>
                  <option value="想收紙本和電子喜帖">想收紙本和電子喜帖</option>
                </select>
              </div>

              <div className="mc-field-group mt-8">
                <label htmlFor="mce-EMAIL">
                  你的 Email <span className="asterisk">*</span>
                </label>
                <input
                  value={formValues.email}
                  onChange={handleChange}
                  type="email"
                  name="email"
                  className="required email"
                  id="mce-EMAIL"
                  required
                />
              </div>

              {able ? (
                <div className="mc-field-group">
                  <label htmlFor="mce-PHONE">
                    你的電話 <span className="asterisk">*</span>
                  </label>
                  <input
                    value={formValues.phone}
                    onChange={handleChange}
                    type="text"
                    name="phone"
                    className="required text"
                    id="mce-PHONE"
                    required
                  />
                </div>
              ) : null}
              {eCard ? (
                <div className="mc-field-group">
                  <label htmlFor="mce-ADDRESS">
                    你的地址 <span className="asterisk">*</span>
                  </label>
                  <input
                    value={formValues.address}
                    onChange={handleChange}
                    type="text"
                    name="address"
                    className="text"
                    id="mce-ADDRESS"
                    required
                  />
                </div>
              ) : null}

              <div className="mc-field-group">
                <label htmlFor="mce-COMMENT">
                  有什麼話想對我們說呢?
                </label>
                <input
                  value={formValues.comment}
                  onChange={handleChange}
                  type="text"
                  name="comment"
                  className="required text"
                  id="mce-COMMENT"
                  style={{height: "5rem"}}
                />
              </div>
              <div className="optionalParent">
                <div className="clear foot">
                  <input
                    type="submit"
                    name="subscribe"
                    id="mc-embedded-subscribe"
                    className="submitButton"
                    value="送出囉，接好喔"
                  />
                </div>
              </div>
            </>
          )}
        </form>
      </div> */}
    </div>
  ) : null;
}
