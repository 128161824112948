import React, { useEffect } from "react";

const PhotoShoot = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://cdn.jsdelivr.net/npm/publicalbum@latest/embed-ui.min.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <script
        src="https://cdn.jsdelivr.net/npm/publicalbum@latest/embed-ui.min.js"
        async
      ></script>
      <div
        className="pa-gallery-player-widget w-full h-[300px] md: w-[720px] h-[480px] m-auto"
        data-link="https://photos.app.goo.gl/C5mbbnH294wdzzpk8"
        data-title=""
        data-description=""
      >
        <object data="https://lh3.googleusercontent.com/pw/AP1GczOcZ-NyVOQ61cv1NPTvbLgI-e3aBFQc--8sEaPMcpDFw5xP-Fu2Y4WAHdrRwSf0gh34poIx89hsFdULnPfOKdwgDTBW9ER_vZYJQ15lNP1CyfYeacBZ=w1920-h1080"></object>
        <object data="https://lh3.googleusercontent.com/pw/AP1GczMt2r24MsQUque1Jyeo_W6nTMGop6lidCvzZLlLPwzFeaC1ZRsn2OXpOLxheWrq4voMCfuTohy93dpSr8-2kV2pmAlihocpdntDvP1CKLbbt3jHsovY=w1920-h1080"></object>
        <object data="https://lh3.googleusercontent.com/pw/AP1GczPdGHmJ77hQG9KVSa_J5f7svUjvV9Pn_zy1AyTvcrnp3Qy0m-V4-DmJB1GSu3805QA-e92xg3aKWi-VN3TzQaBDJUZC7KLEBTNcAjFwd5VPTzVAwIiE=w1920-h1080"></object>
        <object data="https://lh3.googleusercontent.com/pw/AP1GczOKtsrwen4tK-RkI54ELV5a37kGZvDTLKZOC8OpSMvlVTxP0bkfQm-Wlh8_MdIH7dg9jEpVYbD-EzP5hNbGNka8roa7xSm9ITzaAiOvFhAAMauxW1dU=w1920-h1080"></object>
        <object data="https://lh3.googleusercontent.com/pw/AP1GczNJaNAf6grgRXsHkQthaLIxjohlHa3mBl4xtEeSlqqG_cRTpinHNki18zN4xu9AYahJtUwql8KanfaW2iEPKYOSmSQmCARbJJgMuND7eq9WG7fXIuaq=w1920-h1080"></object>
        <object data="https://lh3.googleusercontent.com/pw/AP1GczNF_aTFcna4t015G_0AtUlAiMWLtIT13WRTtE0cO2MYyvjdOErmfSu4mgp7Rdaf-Bd18ETWI_8oQTKuBhk3JB5TYIOS7ywP1w-86t9-rxDs-BZXXFWQ=w1920-h1080"></object>
        <object data="https://lh3.googleusercontent.com/pw/AP1GczO7jxwUN5pLfp_mG2yMOJNGfhUkAYYTaB51YvZfHQvjfnKg_ApWjQQGRLv12F0y8e2ZXszBoc2HiYdAaPoPSxE-IIKhxy-pQcUkojAmL1LUIrFFe01F=w1920-h1080"></object>
        <object data="https://lh3.googleusercontent.com/pw/AP1GczNgykwML3ZAq_92M_1p52t5ISXjfU-bRzZJJh4tvekGeLY-WOTavwMVcbb_42ddjSqvRjYW01sbtUVnvjwSkpuLH8U9zQA9s2kt89BH7PsDJRMUhJUl=w1920-h1080"></object>
        <object data="https://lh3.googleusercontent.com/pw/AP1GczPfZamFnYUzIl4nYemASg2U3b7uazi32rV7aChFgGxbCjECo3G-D6rzAqATI7ri82pCA-J-D4IKoUKYypwb5uxdjgqn6zdTAmO6VwN_SDTpYUI7VQPm=w1920-h1080"></object>
        <object data="https://lh3.googleusercontent.com/pw/AP1GczO_a7KFKhN8TSMLdZIX_RxcG2c_WDIsERJJ-lKTHkDhrCTJaHiT23OuYlloF_dgEZVf-6KAgNGUcvomUrpidcUilK4m_LuvMD-YDAayZqs-3SzWfZ2U=w1920-h1080"></object>
        <object data="https://lh3.googleusercontent.com/pw/AP1GczN7HSb0F4nOBLLqtZ5snsgCjjLY3IKa33wQhXFM3I-uyD6ZznyZj6BiM29Rp1fVtLafxTHbdUw5V7sD-QydlTg-y1cuaINMFHM99m6J9-8C2kYeYQ67=w1920-h1080"></object>
        <object data="https://lh3.googleusercontent.com/pw/AP1GczPSrlR2QvgTc6hBxdaBbqStojECLz8Xw3YMee0_VQDw15Rffai69AF8N93M7QRfAwUk_c_U7uAELCZnSQ3gNhw31U2DKq4y0GLDwPfo2XJiBPp9xTos=w1920-h1080"></object>
        <object data="https://lh3.googleusercontent.com/pw/AP1GczPHWRzY-HSF8A3O3LsuHW2pF1rITRhmXGiFFFDGE3EorFUozeWQhb7NisF-49eyVXToZRLbBvzjhj-WajHLpBwDwbpehFcS9iBE9d4s--E0rjvk83f1=w1920-h1080"></object>
        <object data="https://lh3.googleusercontent.com/pw/AP1GczM2c5zjKITx1dy2L4A8RpgCZiDM3BT0ql9bDn1ywovR-EU8-SHNihV3b5gfSSdiMamslJWfMsqFlBjLmM3CdnWx32UV2oSVZfbEUuzq-KAVVMkyJLXK=w1920-h1080"></object>
        <object data="https://lh3.googleusercontent.com/pw/AP1GczN7qg0EBaQcKPLw3SXHLXUJQ-L7RISdQ8E59kmlrRHgoinMr4RTDNibXtYrmnUyAXZMBOg4H_mYrHhg_nXC8wItPBnxaV5IxLVOwBiVQmRQzx9LKit6=w1920-h1080"></object>
        <object data="https://lh3.googleusercontent.com/pw/AP1GczO_nOto1rlFSc57UC9GPdCC03BV4mWWsFEe7dBtGOgaDGi0iJb5D94Tr12e8vQ9qOBOsEmaSRB5_DMkboupf1um4k1q1sGHMg5RFEz4gLuzyGE-YL8o=w1920-h1080"></object>
        <object data="https://lh3.googleusercontent.com/pw/AP1GczM-AmcTwI7Hb71lpIbAz_xiro8XpZL_F7aEx5Dfp6NYP03-5zrU6Gyk_u33yxX10gA281xSs7Dunrc0RDl7wNdqGZNitUKgsgtAvZsCGw6rMtG89Klo=w1920-h1080"></object>
        <object data="https://lh3.googleusercontent.com/pw/AP1GczPEs0aULAfBEO9lENN-ulsDcIuuHexKdEacH-i8fwRxi9ZaEXVjkEWC1dxhts4kULJ8Y89_UjddjP-h3PYd34T5EqFoWfWlk34kIMXIoPvTPL-Ae5AK=w1920-h1080"></object>
        <object data="https://lh3.googleusercontent.com/pw/AP1GczP7wDbpjoVTq4e4s18cu3LAzLAJn76TA0cWvSpgH8dlyAC_bla8PnjM-sYzTS34LVVhOxNxBzeDLd0WpZVd0owFfq82GRq9BeqCUT2C9W-bfCISBmwf=w1920-h1080"></object>
        <object data="https://lh3.googleusercontent.com/pw/AP1GczPUJZ8RHtG_F5Uso5S4zk9J2OTVyCQOdqdNmApAqTXZYMj6v1hzP37TRkCWClcpF3j6j9GkYNp6RXN4C1djCgJ9VC4RaC5kxaf93rCRrTK4xQJOBhTI=w1920-h1080"></object>
      </div>
    </div>
  );
};

export default PhotoShoot;
