import React from 'react';
import { Link } from 'react-router-dom';

const Nav = () => {

    return (
        <nav className="sticky top-0 flex flex-col items-center p-4 bg-beige z-10">
            <div className="flex flex-col m-auto p-4 max-w-[1000px]">
                <div className="flex justify-center">
                    <Link to="/"><img className="w-60" src="/images/logo.svg" alt="logo"/></Link>
                </div>
                <ul className="text-0 flex justify-center space-x-4 pt-8 text-primary">
                    {/* <li>
                        <Link to="/about" className="hover-bg-slide">誰R</Link>
                    </li> */}
                    <li>
                        <Link to="/invitation" className="underline underline-offset-4 hover-bg-slide">電子喜帖</Link>
                    </li>
                    <li>
                        <Link to="/location" className="underline underline-offset-4 hover-bg-slide">交通資訊</Link>
                    </li>
                    <li>
                        <Link to="/photos" className="underline underline-offset-4 hover-bg-slide">照片們</Link>
                    </li>
                    <li>
                        <Link to="/videos" className="underline underline-offset-4 hover-bg-slide">影片們</Link>
                    </li>
                    <li>
                        <Link to="/rsvp" className="underline underline-offset-4 hover-bg-slide">出席調查 (關閉)</Link>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default Nav;
