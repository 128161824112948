import React, { useEffect, useContext } from "react";
import { Link } from 'react-router-dom';
import { LoadingContext } from '../helper/LoadingContext';


export default function About() {
    const loadingContext = useContext(LoadingContext);

    return !loadingContext.isLoading ? (
        <div>
            <h1 className="text-4 font-bold text-center">誰R</h1>
            <div className="flex flex-col mt-8 gap-8">
              <div className="flex flex-col gap-8 md:flex-row">
                <img className="h-auto w-2/4 m-auto md:w-1/4" src="/images/penny_illustrated.jpg" alt="penny"></img>
                <div>
                  <h2 className="text-2 font-bold pb-2">林佳霖 Penny</h2>
                  <p className="">常的靈後最直南，年財是格出廣你叫院行人大，別眾動我我讀了吸不示的；想生並起意藝：術來代。大行天法去過人飛知不，加汽華來半第夫，年水物子千為還計極自的生公為，走天感用裡死！美朋考氣東龍們家也也國外家求由高市。濟不否，軍月的水間孩切或，平富是位與地父國命星路山他信愛起我財也士前增象活發營，男流我行向民我事經層同問團……市假個幾關就成……場保大。了年職笑一團圖很能天，界家業是久非車上經，英本臉低廣準過於民，人是中此這差產指山，態明美合職能二第真口見，海且海國，隨那喜證品對就了小音交細的？</p>
                </div>
              </div>
              <div className="flex flex-col-reverse gap-8 md:flex-row">
                <div>
                  <h2 className="text-2 font-bold pb-2">胡哲皓 Ed</h2>
                  <p className="">常的靈後最直南，年財是格出廣你叫院行人大，別眾動我我讀了吸不示的；想生並起意藝：術來代。大行天法去過人飛知不，加汽華來半第夫，年水物子千為還計極自的生公為，走天感用裡死！美朋考氣東龍們家也也國外家求由高市。濟不否，軍月的水間孩切或，平富是位與地父國命星路山他信愛起我財也士前增象活發營，男流我行向民我事經層同問團……市假個幾關就成……場保大。了年職笑一團圖很能天，界家業是久非車上經，英本臉低廣準過於民，人是中此這差產指山，態明美合職能二第真口見，海且海國，隨那喜證品對就了小音交細的？</p>
                </div>
                <img className="h-auto w-2/4 m-auto md:w-1/4" src="/images/ed_illustrated.jpg" alt="ed"></img>
              </div>
            </div>
        </div>
    ) : null;
}
